//
//** Base Settings
//

$m-config-base: (
	//== Loader
	loader: (
		self: (
			bg-color: #fff,
			zindex: 1000			
		),
		animation: (
			speed: 1.2s,
			delay: 1s
		),
		spinner: (
			bg-color: #eee,
			size: 30px
		)		
	),

	//== page
	body: (
		content: (
			padding: (
				desktop: (
					x: 30px,
					y: 30px
				),
				mobile: (
					x: 20px,
					y: 20px
				)
			),
			bg-color: (
				light: #ffffff,
				light2: #f2f3f8
			)
		)
	)
);