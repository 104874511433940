//
//** Demo Config
//

//== Layout Shared Settings
$m-aside-left-width: 255px;
$m-aside-left-width-minimize: 100px;
$m-aside-left-offcanvas-width: 255px;

//== Secondary brand color
$m-secondary-brand-color: #F4DA70;
$m-secondary-brand-inverse-color: #FFF;
$m-secondary-brand-hover-color: lighten($m-secondary-brand-color, 26%);
$m-secondary-brand-hover-color-alt: darken($m-secondary-brand-color, 15%);
