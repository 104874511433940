//
//** Subheader Search
//

//== Mixin
@mixin m-build-subheader-search-base() {
	$skin: dark;
	$m-base-color: #574cc6;
	$m-border-color: lighten($m-base-color, 8%);
	$m-icon-color: #22b9ff;

	//== General Mode
	.m-subheader-search {
		background: #564FC1;
		padding: 40px 30px 40px 30px;

		//== Title
		.m-subheader-search__title {
			font-size: 1.8rem;
			font-weight: get-font-weight(bolder);
			color: #fff;
			margin: 0;
		}

		//== Desc
		.m-subheader-search__desc {
			font-size: 1rem;
			font-weight: get-font-weight(bolder);
			color: #9388fa;
			display: inline-block;
			padding-left: 0.5rem;
		}

		.m-input-icon {
			margin-right: 15px;
		}

		.m-input-icon__icon { 
			i {
				color: $m-icon-color;
			}
		}

		.form-control {			
			padding-top: 0.75rem;
			padding-bottom: 0.75rem;
			background: transparent;
			border-color: $m-border-color;
			font-size: 1rem;

			color: lighten($m-border-color, 10%);
			@include input-placeholder( lighten($m-border-color, 5%) );
			
			&:focus {
				border-color: lighten($m-border-color, 10%); 
				@include input-placeholder( lighten($m-border-color, 10%) );
				color: lighten($m-border-color, 13%);

				& + .m-input-icon__icon i{
					color: lighten($m-icon-color, 5%);
				}
			}
		}

		.m-subheader-search__submit-btn {
			text-transform: uppercase;
			padding: 0.9rem 1.5rem;		
			background: $m-icon-color;
			border-color: $m-icon-color; 
			font-size: 0.9rem;
			color: #fff;

			&:hover {
				background: lighten($m-icon-color, 5%);
				border-color: lighten($m-icon-color, 5%);
			}	
		}

		.m-subheader-search__link {
			display: inline-block;  
			margin-left: 20px;
			font-weight: get-font-weight(bolder);
			color: #9388fa;
			
			&:hover {
				color: lighten(#9388fa, 7%); 
			}

			@include m-set-component--typography-link-color( #9388fa, lighten(#9388fa, 7%) );			
		}

		.m-form {
			margin-top: 2rem;
		}		
	}

	//== Minimal Desktop mode
	@include minimal-desktop {
		.m-subheader-search {

		}
	}

	//== Mobile Mode
	@include tablet-and-mobile {
		.m-subheader-search {
			.m-input-icon {
				width: auto;
				margin-bottom: 10px;
			}
		}
	}
}

//== Build
@include m-build-subheader-search-base();