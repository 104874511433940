//
//** Header Settings
//

$m-config-header: (
	//== Header
	header: (
		// Default mode
		default: (
			height: (
				desktop: 80px,
				mobile: 60px
			),
			bg-color: #ffffff
		),
		// Fixed mode
		fixed: (		 
			zindex: 101,
			hidable: (			
				transition-hide: all 0.3s ease 0.5s,
				transition-show: all 0.3s ease, 
			)
		)
	),

	//== Title
	title: (
		padding: (
			desktop: 0 40px 0 30px,
			min-desktop: 0 10px 0 30px,
			mobile: 0 0 0 5px
		),
		font-size: (
			mobile: 1.2rem,
			desktop: 1.4rem
		),
		color: #4C4C50
	),

	//== Brand
	brand: (
		// brand itself
		self: (
			bg-color: #ffffff,
			padding: (
				desktop: 0px 30px,
				mobile: 0px 25px
			),
			width: (
				default: $m-aside-left-width,
				minimize: $m-aside-left-width-minimize
			)
		),

		// logo
		logo: (
			width: (
				mobile: 40px
			)
		),

		// brand tools
		tools: (
			// itself
			self: (
				// space between togglers and icons
				space: (
					mobile: 10px 
				)
			),
			// toggler
			toggler: (
				width: 26px,
				height: 26px,  
				transition-duration: 0.4s,
				thickness: 1px,
				space: 6px, 
				radius: 0px,
				color: (
					default: #5d5f77,
					hover: get-brand-color(),
					active: get-brand-color()
				)
			),

			// icon
			icon: (
				font-size: 1.4rem,
				color: (
					default: #5d5f77,
					hover: lighten(#5d5f77, 20%),
					active: lighten(#5d5f77, 20%)
				)
			)
		)			
	),

	//== Topbar
	topbar: (
		// Default mode
		default: (		
			// self
			self: (
				bg-color: #fff,
				padding: (
					desktop: 0 20px,
					min-desktop: 0 10px,
					tablet-and-mobile: 0 3px 0 10px
				),
				transition-time: (
					push: .6s
				)
			),

			// nav bar
			nav: (
				// nav itself 
				self: (
					margin: (
						desktop: 0 0 0 20px,
						min-desktop: 0 2px,
						tablet-and-mobile: 0
					)
				),

				// nav item
				item: (
					// self
					self: (
						height: 3rem,
						padding: (
							desktop: 0 5px,
							min-desktop: 0 3px,
							tablet-and-mobile: 0 4px
						)
					),

					// item link
					link: (
						// link icon
						icon: (
							font-size: 1.5rem,
							align: center,
							color:	#b4b2cc,
							border: #e8e7f4
						),

						// user link
						user: (
							// username
							username: (
								align: left,
								font-size: 0.9rem,
								font-weight: 400,
								font-transform:	uppercase,
								font-color:	(
									default: #fff,
									active: get-brand-color(),
									hover: get-brand-color(),
									disabled: #eee
								)
							),

							// userpic
							userpic: (
								max-width: (
									desktop: 41px,
									mobile: 31px
								),
								margin: 0 0 0 5px
							)
						),

						// link badge
						badge:	(
							top: (
								desktop: 8px,
								mobile: 5px
							),
							margin-left: (
								desktop: -2px,
								mobile: -2px
							)	
						)
					),
				)
			)
		),

		// Mobile toggle mode
		toggle: (
			transition: all 0.3s ease,
			bg-color: #fff,
			box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.1),
			height: (
				mobile: 60px
			)					
		)
	),

	//== search
	search: (
		// self
		self: (
			transition-time: 0.4s,
			width: (
				desktop: (
					expandable: (
						initial: 160px,
						expanded: 240px
					),
					default: 180px
				),
				min-desktop: (
					expandable: (
						initial: 70px,
						expanded: 120px
					),
					default: 100px
				)						
			),
			border-radius: 30px
		),

		// input
		input: (
			font-size: 0.9rem,
			padding: (
				desktop: 7px 10px 7px 5px,
				mobile: 7px 10px 7px 5px
			),
			bg-color: (
				desktop: transparent,
				mobile: #ffffff
			),
			border: (
				desktop: #e8e7f4,
				mobile: #e8e7f4
			),
			font-color: (
				desktop: #908db0,
				mobile: #908db0
			),					
			placeholder-color: (
				desktop: #a6a3c3,
				mobile: #a6a3c3
			)
		),

		// icon
		icon: (
			// search icon
			search: (
				width: (
					desktop: 30px,
					mobile: 30px
				),
				padding: (
					desktop: 0 0 0 10px,
					mobile: 0 0 0 10px
				),
				font-size: (
					desktop: 1rem,
					mobile: 1.3rem
				),
				font-color: (
					desktop: #bcc0cd,
					mobile:  #bcc0cd
				)
			),

			// cancel icon for desktop mode
			cancel: (
				width: (
					desktop: 30px
				),
				padding: (
					desktop: 0 10px 0 0
				),
				font-size: (
					desktop: 1.2rem
				),
				font-color: (
					desktop: #bcc0cd
				)
			),

			// close icon for mobile mode
			close: (
				width: (
					mobile: 30px
				),
				padding: (
					mobile: 0 20px 0 0
				),
				font-size: (
					mobile: 1.3rem
				),
				font-color: (
					mobile:  #bcc0cd
				)
			)
		),

		// spinner 
		loader: (
			color: #282a3c,
			right: (
				desktop: 15px,
				mobile: 10px
			)
		)
	)
);